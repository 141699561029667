<template>
  <Breadcrumb />
  <div
    class="accordion mb-3 mt-2"
    :id="String.format('Accordion_{0}', gridSettings.action)"
    ref="accordion"
  >
    <div class="accordion-item">
      <h2
        class="accordion-header"
        :id="String.format('Accordion_{0}_head', gridSettings.action)"
      >
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="
            String.format('#Accordion_{0}_tab1', gridSettings.action)
          "
          aria-expanded="true"
          :aria-controls="
            String.format('Accordion_{0}_tab1', gridSettings.action)
          "
        >
          <p class="text-capitalize fs-5 mb-0">
            {{
              $t(
                "RecordLogs.PageFilter",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </p>
        </button>
      </h2>
      <div
        :id="String.format('Accordion_{0}_tab1', gridSettings.action)"
        class="accordion-collapse collapse show"
        :aria-labelledby="
          String.format('Accordion_{0}_head', gridSettings.action)
        "
        :data-bs-parent="String.format('Accordion_{0}', gridSettings.action)"
      >
        <div class="accordion-body">
          <div class="row align-items-center">
            <div class="col-md-2">
              <label for="" class="">{{
                $t(
                  "RecordLogs.CustomObject",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
            </div>
            <div class="col-md-4">
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                :isDetailIconShow="true"
                :resetSelect="selectsReset"
                :routeLink="String.format('#/CustomObject/Detail/')"
                @onChange="onChangeCustomObject"
                :allowEmpty="false"
                :isParameters="false"
                :isGatewayRequest="true"
                requestUrl="/Lcdp-SummaryCustomObjects"
              />
            </div>
            <div class="col-md-2">
              <label for="RecordPublicId" class="">{{
                $t(
                  "RecordLogs.RecordPublicId",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
            </div>
            <div class="col-md-4">
              <input
                type="text"
                id="RecordPublicId"
                class="form-control"
                v-model="historyFieldRecordPublicId"
              />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-md-2">
              <label for="" class="">{{
                $t(
                  "BaseModelFields.Field",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
            </div>
            <div class="col-md-4">
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                :resetSelect="selectsReset"
                @onChange="onChangeField"
                :allowEmpty="false"
                :isParameters="true"
                :isGatewayRequest="true"
                :requestUrl="
                  String.format(
                    '/Lcdp-SummaryFields?coId={0}',
                    this.customObjectPublicId
                  )
                "
                :isDetailIconShow="true"
                :routeLink="
                  String.format(
                    '#/CustomObject/EditField/{0}&fieldId=',
                    this.customObjectPublicId
                  )
                "
                :isDisabled="
                  String.isNullOrWhiteSpace(this.customObjectPublicId)
                    ? true
                    : false
                "
              />
            </div>
            <div class="col-md-2">
              <label for="" class="">{{
                $t(
                  "BaseModelFields.CreatedBy",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
            </div>
            <div class="col-md-4">
              <FormSelect
                :resetSelect="selectsReset"
                type="SelectWithRemoteDataAndInternalSearch"
                :isDetailIconShow="true"
                @onChange="onChangeCreatedBy"
                :allowEmpty="false"
                :isParameters="false"
                :isGatewayRequest="true"
                requestUrl="/Lcdp-SummaryListUsersWithNoGroup"
              />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-md-2">
              <label for="formulaErrorLogStartDate" class="">{{
                $t(
                  "RecordLogs.StartDate",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
            </div>
            <div class="col-md-4">
              <DatePicker
                id="formulaErrorLogStartDate"
                type="datetime"
                :isReturnMaskedValue="true"
                :displayFormat="$store.getters._dateTimeFormat"
              />
            </div>
            <div class="col-md-2">
              <label for="formulaErrorLogEndDate" class="">{{
                $t(
                  "RecordLogs.EndDate",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
            </div>
            <div class="col-md-4">
              <DatePicker
                id="formulaErrorLogEndDate"
                type="datetime"
                :isReturnMaskedValue="true"
                :displayFormat="$store.getters._dateTimeFormat"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-2">
              <label for="exceptionMessage" class="">{{
                $t(
                  "FormulaErrorLogs.ExceptionMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
            </div>
            <div class="col-md-10">
              <textarea
                rows="1"
                class="form-control"
                v-model="exceptionMessage"
                id="exceptionMessage"
              ></textarea>
            </div>
          </div>
          <div class="col-md-12 mt-2 text-end">
            <button
              type="button"
              class="btn btn-success btn-history-filter-search me-2"
              @click="onSearch('btn-history-filter-search')"
            >
              <span>
                {{
                  $t(
                    "RecordLogs.Search",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </span>
            </button>
            <button
              type="button"
              class="btn btn-history-filter-clear btn-danger"
              @click="onClear"
            >
              {{
                $t(
                  "RecordLogs.Clear",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="responseError.length > 0" class="alert alert-warning">
    <ul class="mb-0">
      <li v-for="error in responseError" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    @onRequestFinally="onSearchEnd"
  />
</template>
<script>
import { createToast } from "mosha-vue-toastify";
import $ from "jquery";
export default {
  name: "FormulaErrorLogList",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses:
        ".btn-history-filter-search, .btn-history-filter-clear",
      historyFieldRecordPublicId: "",
      exceptionMessage: "",
      customObjects: [],
      fieldPublicId: "",
      actionName: "rws-GetFormulaErrorLogs",
      selectsReset: false,
      responseError: [],
      customObjectPublicId: "",
      createdByPublicId: "",
      gridSettings: {
        action: "FormulaErrorLog",
        requestUrl: "",

        isGatewayRequest: true,

        allowSearchPanel: true,
        allowExcelExport: true,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: false,
        isCommandColumnHide: true,

        autoOrderProcess: true,
        buttons: [],
      },
      gridColumns: [
        // {
        //   text: this.$t(
        //     "Buttons.RecordView",
        //     {},
        //     { locale: this.$store.state.activeLang }
        //   ),
        //   field: "recordPublicId",
        //   type: "string",
        //   visible: true,
        //   width: 200,
        //   template: "",
        //   format: "",
        //   textAlign: "",
        // },
        {
          text: "Custom Object Public Id",
          field: "customObjectPublicId",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: "Record Public Id",
          field: "recordPublicId",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "RecordLogs.User",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "RecordLogs.Date",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: true,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.Field",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "fieldName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "FormulaErrorLogs.Formula",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formula",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "FormulaErrorLogs.ExceptionMessage",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "exceptionMessage",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  mounted() {
    this.getCustomObjects();
  },
  methods: {
    onChangeCustomObject(selected) {
      this.customObjectPublicId = selected.key;
    },
    onChangeField(selected) {
      this.fieldPublicId = selected.key;
    },
    onChangeCreatedBy(selected) {
      this.createdByPublicId = selected.key;
    },
    getCustomObjects() {
      this.$prodGatewayAxios
        .get("/Lcdp-CustomObjectList?page=1&size=1000")
        .then((response) => {
          this.customObjects = response.data.items;
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    onSearch(buttonName) {
      var recordId = this.historyFieldRecordPublicId;
      if (!String.isNullOrWhiteSpace(recordId) && !String.isGuid(recordId)) {
        createToast(
          this.$t(
            "FieldErrors.NoGuidFormat",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: "true",
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      var searchUrl = String.format("{0}", this.actionName),
        searchParams = [];

      if (!String.isNullOrWhiteSpace(this.customObjectPublicId)) {
        searchParams.push({
          key: "customObjectPublicId",
          value: this.customObjectPublicId,
        });
      }

      if (!String.isNullOrWhiteSpace(recordId)) {
        searchParams.push({
          key: "recordPublicId",
          value: recordId,
        });
      }

      var startDate = $("#formulaErrorLogStartDate").val();
      if (!String.isNullOrWhiteSpace(startDate)) {
        searchParams.push({
          key: "startDate",
          value: startDate,
        });
      }

      var endDate = $("#formulaErrorLogEndDate").val();
      if (!String.isNullOrWhiteSpace(endDate)) {
        searchParams.push({
          key: "endDate",
          value: endDate,
        });
      }

      var exceptionMessage = this.exceptionMessage;
      if (!String.isNullOrWhiteSpace(exceptionMessage)) {
        searchParams.push({
          key: "exceptionMessage",
          value: exceptionMessage,
        });
      }

      if (!String.isNullOrWhiteSpace(this.fieldPublicId)) {
        searchParams.push({
          key: "fieldPublicId",
          value: this.fieldPublicId,
        });
      }

      this.gridSettings.requestUrl = ""; //trigger watch parameter
      this.gridSettings.requestUrl = String.createGetUrl(
        searchUrl,
        searchParams
      );
    },
    onSearchEnd() {
      var button = $(String.format(".{0}", "btn-history-filter-search")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      firstSpan.show();
      loadingBlock.hide();
      disabledButtons.prop("disabled", false);
    },
    onClear() {
      this.selectsReset = !this.selectsReset;
      this.historyFieldRecordPublicId = "";
      this.exceptionMessage = "";
      $("#formulaErrorLogStartDate, #formulaErrorLogEndDate").val("");
    },
  },
};
</script>
